import { createTheme } from "@mui/material";

export const theme = createTheme({
  palette: {
    common: { black: "#000", white: "#fff" },
    background: { paper: "#fff", default: "#fafafa" },

    primary: {
      light: "#243341",
      main: "#2C3E50",
      dark: "#5c6d7e",
      contrastText: "#fff",
    },

    secondary: {
      light: "rgba(126, 199, 188, 1)",
      main: "rgba(3, 130, 111, 1)",
      dark: "rgba(0, 71, 55, 1)",
      contrastText: "#fff",
    },

    success: {
      light: "#02664f",
      main: "#049372",
      dark: "#36a88e",
      contrastText: "#fff",
    },

    error: {
      light: "#86271e",
      main: "#C0392B",
      dark: "#cc6055",
      contrastText: "#fff",
    },

    warning: {
      light: "#a46823",
      main: "#EB9532",
      dark: "#efaa5b",
      contrastText: "#fff",
    },

    info: {
      light: "#243341",
      main: "#34495E",
      dark: "#5c6d7e",
      contrastText: "#fff",
    },

    text: {
      primary: "rgba(0, 0, 0, 0.87)",
      secondary: "rgba(0, 0, 0, 0.54)",
      disabled: "rgba(0, 0, 0, 0.38)",
      // hint: "rgba(0, 0, 0, 0.38)",
    },
  },
});
