import React from "react";
import Languages from "../constants/Languages";
import { useDispatch } from "react-redux";
import { changeLanguage } from "../redux/reducers/language";
import Lang from "../types/lang";
import tr from "../utils/translation";
import { Button, Menu, MenuItem } from "@mui/material";

export default function LanguageMenu() {
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeLanguage = (lang: Lang) => {
    dispatch(changeLanguage(lang));
    handleClose();
  };

  return (
    <>
      <Button
        style={{ margin: 15 }}
        aria-controls="language-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        {tr("LANGUAGE")}
      </Button>
      <Menu
        id="language-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {Languages.map((lang) => {
          return (
            <MenuItem onClick={() => handleChangeLanguage(lang)} key={lang.key}>
              {lang.value}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
}
