import { Send } from "@mui/icons-material";
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
} from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { addTeamMember, db, removeTeamMember } from "../../firebase";

export default function TeamProjectDialog({ open, data, handleClick }: any) {
  const { user } = useSelector((state: any) => state.auth);

  const [team, setTeam] = useState([]) as any;
  const [email, setEmail] = useState("");

  useEffect(() => {
    if (open) {
      const q = query(
        collection(db, "users"),
        where("developer_ids", "array-contains", data.id)
      );

      const unsub = onSnapshot(q, (snapshot) => {
        const users = snapshot.docs.map((doc) => doc.data());
        setTeam(users);
      });
      return () => {
        unsub();
      };
    }
  }, [open]);

  const handleEmailChange = (value: string) => {
    setEmail(value);
  };

  const handleAddTeamMember = useCallback(async (userEmail: string) => {
    await addTeamMember(userEmail, data, user.displayName);
    setEmail(""); //clear the email text
  }, []);

  const handleDeleteTeamMember = useCallback(async (userEmail: string) => {
    await removeTeamMember(userEmail, data.id);
  }, []);

  const columns: GridColDef[] = [
    {
      field: "displayName",
      headerName: "Name",
      flex: 1,
      editable: false,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      editable: false,
    },
    {
      field: "action",
      headerName: "Action",
      width: 100,
      editable: false,
      renderCell: (params: any) => {
        return (
          <>
            {params.row.email === data.email ? (
              //if the user is the owner of the project show as admin so he will not be able to delete him self
              <Chip color={"info"} label={"ADMIN"} />
            ) : (
              // only of the user is not admin
              <Button
                size={"small"}
                onClick={() => handleDeleteTeamMember(params.row.email)}
                color="error"
              >
                Delete
              </Button>
            )}
          </>
        );
      },
    },
  ];

  return (
    <Dialog open={open} onClose={handleClick} fullWidth>
      <DialogTitle>{data.project_name}</DialogTitle>
      <DialogContent>
        <FormControl sx={{ mb: 2 }} fullWidth variant="standard">
          <InputLabel htmlFor="standard-adornment-text">
            Invite Team member by Email
          </InputLabel>
          <Input
            id="standard-adornment-text"
            type={"text"}
            value={email}
            onChange={(event) => handleEmailChange(event.currentTarget.value)}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  color="primary"
                  aria-label="toggle send button"
                  onClick={() => {
                    handleAddTeamMember(email);
                  }}
                >
                  <Send />
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>

        <Grid width={"100%"} height={600}>
          <DataGrid
            rows={team}
            columns={columns}
            pageSize={8}
            getRowId={(row) => row.email}
            disableSelectionOnClick
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClick} color={"info"}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
