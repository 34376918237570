import {
  CircularProgress,
  createStyles,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ProjectCard from "../components/Project/ProjectCard";
import { getDeveloperInfo, getUserInfo } from "../firebase";
import { theme } from "../theme";
import Developer from "../types/Developer";

const styles = createStyles({
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },

  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
});

export default function Home() {
  const navigate = useNavigate();

  const is_login = useSelector((state: any) => state.auth.is_login);
  const { user } = useSelector((state: any) => state.auth);
  const [data, setData] = useState([] as any);

  const loadData = async () => {
    const userInfo = await getUserInfo(user?.email);

    let projects = [] as any;
    if (userInfo) {
      await Promise.all(
        userInfo.developer_ids.map(async (id: string) => {
          const developer_info = (await getDeveloperInfo(id)) as Developer;
          projects.push(developer_info);
        })
      );
    }
    setData(projects);
  };
  useEffect(() => {
    if (is_login) {
      loadData();
    }

    // if user is not logged in go to login page
    if (!is_login) navigate("/");
  }, [is_login]);

  return (
    <Grid container flexGrow={1} p={3} justifyContent={"center"}>
      <Grid item xs={12} />
      <Grid item xs={12}>
        {!data.length ? (
          <Stack alignItems={"center"} justifyContent={"center"} flex={1}>
            <CircularProgress />
            <Typography
              margin={2}
              width={"50%"}
              variant="body1"
              color={"GrayText"}
              textAlign={"center"}
            >
              Data is Loading. If it tack more than few seconds, Your account is
              not register as developer yet or you need a developer assign you
              to project.
            </Typography>
          </Stack>
        ) : (
          data.map((project: Developer, index: string) => {
            return <ProjectCard data={project} key={index} />;
          })
        )}
      </Grid>
    </Grid>
  );
}
