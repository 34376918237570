import { ThemeProvider } from "@mui/material/styles";
import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// Theme
import { theme } from "./theme";

// Screens
import HomeScreen from "./Screens/HomeScreen";
import LoginScreen from "./Screens/LoginScreen";
import ResetPasswordScreen from "./Screens/ResetPasswordScreen";
import ProjectLogsScreen from "./Screens/ProjectLogsScreen";
import ClientsScreen from "./Screens/ClientsScreen";

// Components
import MenuAppBar from "./components/MenuAppBar";
import Sign_upScreen from "./Screens/Sign_upScreen";

function App() {
  useEffect(() => {
    document.title = "LW Developer";
  }, []);
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <MenuAppBar />
        <Routes>
          <Route path="/" element={<LoginScreen />} />
          <Route path="/sign_up" element={<Sign_upScreen />} />
          <Route path="/reset_password" element={<ResetPasswordScreen />} />

          <Route path="/home" element={<HomeScreen />} />
          <Route path="/clients" element={<ClientsScreen />} />
          <Route path="/projectLogs" element={<ProjectLogsScreen />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
