import { createSlice } from "@reduxjs/toolkit";
import Languages from "../../constants/Languages";
import Lang from "../../types/lang";

export const language = createSlice({
  name: "language",
  initialState: {
    key: "en",
    type: "ltr",
    value: "English",
    primary: true,
  } as Lang,
  reducers: {
    changeLanguage: (state, action) => {
      const selected = Languages.findIndex(
        (lang) => lang.key === action.payload.key
      );
      state = { ...Languages[selected], primary: true };
      return state;
    },
  },
});

// Action creators are generated for each case reducer function
export const { changeLanguage } = language.actions;

export default language.reducer;
