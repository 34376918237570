import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  Link,
  Snackbar,
  ThemeProvider,
  Typography
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import LanguageMenu from "../components/LanguagesMenu";
import { login } from "../firebase";
import { set_is_login, set_user_logged_in } from "../redux/reducers/manageAuth";
import { theme } from "../theme";
import Lang from "../types/lang";
import tr from "../utils/translation";

export default function LoginScreen() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // get the language and check if user isLogin from the redux store
  const lang = useSelector((state: any) => state.language as Lang);
  const is_login = useSelector((state: any) => state.auth.is_login);

  // set the state of the login form
  const [email, set_email] = useState<string>("");
  const [password, set_password] = useState<string>("");
  const [showPassword, setShowPassword] = useState(false);

  // error handling state
  const [showError, setShowError] = useState<boolean>(false);
  const [isValidInput, setIsValidInput] = useState<boolean>(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  // handle error message
  const showErrorMessage = () => {
    setShowError(!isValidInput);
  };
  const hideErrorMessage = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') return;
    setShowError(false);
  };

  // handle login
  const onSignIn = useCallback(async () => {
    // login with email and password if valid return user
    const user = await login(email, password);
    // check if user is valid
    if (user && user.email === email) {
      dispatch(set_is_login(true));
      dispatch(set_user_logged_in(user));
      navigate("/home");
    }
    // invalid email or password
    if (!user) {
      setIsValidInput(true);
    }
    // if user is not valid show error
    showErrorMessage();
  }, [email, password]);

  useEffect(() => {
    // if user is logged in go to home page
    if (is_login) navigate("/home");
  }, [is_login]);

  return (
    <ThemeProvider theme={theme}>
      <div dir={lang.type}>
        <Grid
          container
          direction={"column"}
          alignItems={"center"}
          justifyContent={"center"}
          style={{ minHeight: "87vh" }}
        >
          <Container maxWidth={"xs"}>
            <Typography component="h1" variant="h5">
              {tr("DO_SIGN_IN")}
            </Typography>
            <Box component="form">
              <Box style={{ marginTop: 15, marginBottom: 15 }}>
                <InputLabel required>{tr("EMAIL")}</InputLabel>
                <Input
                  error={isValidInput}
                  required
                  fullWidth
                  id="email"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  onChange={(event) => {
                    set_email(event.target.value);
                  }}
                />
              </Box>

              <Box style={{ marginTop: 15, marginBottom: 15 }}>
                <InputLabel required>{tr("PASSWORD")}</InputLabel>
                <Input
                  error={isValidInput}
                  required
                  fullWidth
                  name="password"
                  type={showPassword ? "text" : "password"}
                  id="password"
                  autoComplete="current-password"
                  onChange={(event) => {
                    set_password(event.target.value);
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </Box>

              <Button
                variant="contained"
                fullWidth
                color={"primary"}
                onClick={onSignIn}
              >
                {tr("DO_SIGN_IN")}
              </Button>

              <Grid container>
                <Grid item xs>
                  <Link href="/reset_password" variant="body2">
                    {tr("FORGOT_PASSWORD")}
                  </Link>
                </Grid>
                <Grid item>
                  <Link href="/sign_up" variant="body2">
                    {`${tr("DONT_HAVE_ACCOUNT")} ${tr("SIGN_UP")}`}
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Container>
          <LanguageMenu />
        </Grid>
        <Snackbar open={showError} autoHideDuration={7000} onClose={hideErrorMessage}>
        <Alert onClose={hideErrorMessage} severity="error" sx={{ width: '100%' }} variant="filled">
          Invalid input: wrong email or password
        </Alert>
      </Snackbar>
      </div>
    </ThemeProvider>
  );
}
