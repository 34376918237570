import React, { useEffect, useState } from "react";
import { db } from "../firebase";
import {
  onSnapshot,
  collection,
  query,
  orderBy,
  limit,
} from "firebase/firestore";
import LogsDialog from "./Project/LogsDialog";
import { Chip, CircularProgress, Grid, Stack, Typography } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { theme } from "../theme";

export const RenderMethod = ({ method, size = "small" }: any) => {
  const methodColor = (method: string): string => {
    switch (method) {
      case "POST":
        return "success";
      case "PUT":
        return "warning";
      case "DELETE":
        return "error";
      default:
        return "info";
    }
  };

  const color = methodColor(method) as "error" | "info" | "success" | "warning";

  return (
    <Stack
      bgcolor={theme.palette[color].main}
      borderRadius={"3px"}
      width={80}
      textAlign={"center"}
      p={"4px"}
    >
      <Chip label={method} color={color} size={size} />
    </Stack>
  );
};

export const RenderStatus = ({ response }: any) => {
  const color =
    response.status === 200
      ? theme.palette.success.light
      : theme.palette.error.light;
  return (
    <Typography fontWeight={"bold"} fontSize={14} color={color}>
      {response.status}
    </Typography>
  );
};

const columns: GridColDef[] = [
  {
    field: "Status",
    headerName: "Status",
    width: 130,
    renderCell: (params: any) => {
      const response = JSON.parse(params.row.response);
      return <RenderStatus response={response} />;
    },
  },
  {
    field: "method",
    headerName: "Method",
    width: 130,
    editable: false,
    renderCell: (params: any) => {
      return <RenderMethod method={params.row.method} />;
    },
  },
  {
    field: "route",
    headerName: "Route",
    flex: 1,
    minWidth: 180,
    renderCell: (params: any) => {
      const version = params.row.version;
      const route = params.row.route;

      return (
        <Typography fontWeight={"bold"} fontSize={16}>
          {`/${version}${route}`}
        </Typography>
      );
    },
  },
  {
    field: "request_time",
    headerName: "Time",
    flex: 1,
    minWidth: 180,
    renderCell: (params: any) => {
      return (
        <Typography>{`${params.row.request_time
          .toDate()
          .toDateString()} | ${params.row.request_time
          .toDate()
          .toLocaleTimeString()}`}</Typography>
      );
    },
  },
  {
    field: "action",
    headerName: "Action",
    width: 150,
    renderCell: (params: any) => {
      const method = params.row.method;
      const route = params.row.route;
      const headers = params.row.headers ? JSON.parse(params.row.headers) : {};
      const body = JSON.parse(params.row.body);
      const query = JSON.parse(params.row.query);
      const response = JSON.parse(params.row.response);
      const request_time = `${params.row.request_time
        .toDate()
        .toDateString()} | ${params.row.request_time
        .toDate()
        .toLocaleTimeString()}`;
      const version = params.row.version;

      const data = {
        method: method,
        route: `/${version}${route}`,
        headers: headers,
        body: body,
        query: query,
        response: response,
        request_time: request_time,
      };
      return <LogsDialog data={data} />;
    },
  },
];

export default function OperationTable({ project_id }: any) {
  const [rows, set_rows] = useState<any>([]);

  useEffect(() => {
    const unsub = onSnapshot(
      query(
        collection(db, `developers/${project_id}/operations`),
        orderBy("request_time", "desc"),
        limit(1000)
      ),
      (snapshot) => {
        const data = snapshot.docs.map((doc) => {
          return { ...doc.data(), id: doc.id };
        });
        set_rows(data);
      }
    );

    return () => {
      unsub();
    };
  }, []);

  if (!rows.length) return <CircularProgress />;

  return (
    <Grid width={"100%"} height={775}>
      <DataGrid
        rows={rows}
        columns={columns}
        autoPageSize
        disableSelectionOnClick
      />
    </Grid>
  );
}
