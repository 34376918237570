import { initializeApp } from "firebase/app";
import {
  createUserWithEmailAndPassword,
  getAuth,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { invert } from "lodash";
import Invite from "./types/Invite";

// Initialize Firebase

const firebaseConfig: any = {
  apiKey: "AIzaSyDAFL_pNEGvPFGQrIei4WsgXZbss7lij0s",
  authDomain: "lazywaitapi.firebaseapp.com",
  databaseURL: "https://lazywaitapi.firebaseio.com",
  projectId: "lazywaitapi",
  storageBucket: "lazywaitapi.appspot.com",
  messagingSenderId: "481158613384",
  appId: "1:481158613384:web:2a826e0ec1fdc45ec3523d",
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);

export const logout = async () => {
  return signOut(auth)
    .then(() => {
      // Sign-out successful.
      return true;
    })
    .catch((error) => {
      // An error happened.
      return false;
    });
};

export const login = async (email: string, password: string) => {
  return signInWithEmailAndPassword(auth, email, password)
    .then(async (userCredential) => {
      // Signed in
      return await getUserInfo(userCredential.user.email || "");
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      return null;
    });
};

export const sign_up = async (email: string, password: string) => {
  return createUserWithEmailAndPassword(auth, email, password)
    .then(async (userCredential) => {
      // Signed in
      console.log(userCredential.user.uid);

      await setDoc(doc(collection(db, "users"), userCredential.user.uid), {
        id: userCredential.user.uid,
        displayName: userCredential.user.email?.split("@")[0] || "", // get email before the @ to be the display name
        phoneNumber: userCredential.user.phoneNumber,
        photoURL: userCredential.user.photoURL,
        email: userCredential.user.email,
        emailVerified: userCredential.user.emailVerified,
        developer_ids: [],
        invitations: [],
      });

      return userCredential.user;
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;

      return null;
    });
};

export const restPassword = async (email: string) => {
  sendPasswordResetEmail(auth, email)
    .then(() => {
      // Password reset email sent!
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      // ..
    });
};

export const getDeveloperInfo = async (developer_id: string) => {
  const docRef = doc(db, "developers", developer_id);
  const developerInfo = await getDoc(docRef);

  if (developerInfo.exists()) {
    const data = developerInfo.data();

    return data;
  } else {
    // No such document found!
    return null;
  }
};

export const getUserInfo = async (email: string): Promise<any> => {
  let userInfo = null;
  const q = query(collection(db, "users"), where("email", "==", email));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    if (email === doc.data().email) userInfo = doc.data();
  });

  return userInfo;
};

export const updateWebhook = async (id: string, new_webhook_url: string) => {
  const ref = doc(db, "developers", id);

  await updateDoc(ref, {
    webhook_url: new_webhook_url,
  });
};

export const updateLogOperation = async (
  id: string,
  log_operations: boolean
) => {
  const ref = doc(db, "developers", id);

  await updateDoc(ref, {
    log_operations: log_operations,
  });
};

export const getOperationInfo = async (id: string): Promise<any> => {
  const q = collection(db, `developers/${id}/operations`);
  const snapshot = await getDocs(q);
  const data = snapshot.docs.map((doc) => {
    return { ...doc.data(), id: doc.id };
  });
  return data;
};

export const getDeveloperTeam = async (developer_id: string) => {
  const q = query(
    collection(db, "users"),
    where("developer_ids", "array-contains", developer_id)
  );

  const querySnapshot = await getDocs(q);
  const data = querySnapshot.docs.map((doc) => doc.data());

  return data;
};

export const removeTeamMember = async (email: string, developer_id: string) => {
  const user = await getUserInfo(email);

  const removeIndex = user.developer_ids.indexOf(developer_id);
  user.developer_ids.splice(removeIndex, 1); // remove project for this user

  const q = query(collection(db, "users"), where("email", "==", email));
  const snapshot = await getDocs(q);

  snapshot.docs.map((doc) => {
    return setDoc(doc.ref, user); // update user info after remove the project id
  });
};

export const addTeamMember = async (
  email: string,
  data: any,
  senderName: string
) => {
  const user = await getUserInfo(email);

  // check if user is a team member in the project ignore invite
  const isInvited = user.invitations.find(
    (invite: Invite) => invite.developer.id === data.id
  );
  const isInvitePadding = user.developer_ids.includes(data.id);

  if (!isInvitePadding && !isInvited) {
    user.invitations.push({
      developer: { id: data.id, project_name: data.project_name },
      from: { email: data.email, name: senderName },
    } as Invite); // adding project for this user

    const q = query(collection(db, "users"), where("email", "==", email));
    const snapshot = await getDocs(q);

    snapshot.docs.map((doc) => {
      return setDoc(doc.ref, user); // update user info after adding the project id
    });
  }
};

export const manageInvite = async (
  type: string,
  email: string,
  developer_id: string
) => {
  const user = await getUserInfo(email);

  if (type === "ACCEPT") user.developer_ids.push(developer_id); // adding project for this user

  const q = query(collection(db, "users"), where("email", "==", email));
  const snapshot = await getDocs(q);

  await Promise.all(
    snapshot.docs.map(async (doc) => {
      const removeIndex = user.invitations.forEach(
        (invite: Invite, index: number) =>
          invite.developer.id === developer_id ? index : -1
      );
      if (removeIndex !== -1) {
        user.invitations.splice(removeIndex, 1); // remove the invitation
        await setDoc(doc.ref, user); // update user info after adding the project id
      }
    })
  );
};
