import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  Link,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import LanguageMenu from "../components/LanguagesMenu";
import { login, sign_up } from "../firebase";
import { set_is_login, set_user_logged_in } from "../redux/reducers/manageAuth";
import { theme } from "../theme";
import Lang from "../types/lang";
import tr from "../utils/translation";

export default function Sign_upScreen() {
  const dispatch = useDispatch();
  const lang = useSelector((state: any) => state.language as Lang);

  const [email, set_email] = useState<string>("");
  const [password, set_password] = useState<string>("");
  const [re_enter_password, set_re_enter_password] = useState<string>("");

  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordReEnter, setShowPasswordReEnter] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleClickShowPasswordReEnter = () => {
    setShowPasswordReEnter(!showPasswordReEnter);
  };

  const [isValidInput, setIsValidInput] = useState<boolean>(false);
  const [isPasswordMatch, setIsPasswordMatch] = useState<boolean>(false);

  const navigate = useNavigate();

  const onSignUp = useCallback(async () => {
    setIsPasswordMatch(password === re_enter_password);

    if (password === re_enter_password) {
      // sign up with email and password if valid return user
      const user = await sign_up(email, password);
      // check if user is valid
      if (user && user.email === email) {
        navigate("/");
      }
      // invalid email or password
      if (!user) {
        setIsValidInput(true);
      }
    }
  }, [email, password, re_enter_password]);

  return (
    <ThemeProvider theme={theme}>
      <div dir={lang.type}>
        <Grid
          container
          direction={"column"}
          alignItems={"center"}
          justifyContent={"center"}
          style={{ minHeight: "87vh" }}
        >
          <Container maxWidth={"xs"}>
            <Typography component="h1" variant="h5">
              {tr("SIGN_UP")}
            </Typography>
            <Box component="form">
              <Box style={{ marginTop: 15, marginBottom: 15 }}>
                <InputLabel required>{tr("EMAIL")}</InputLabel>
                <Input
                  error={isValidInput}
                  required
                  fullWidth
                  id="email"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  onChange={(event) => {
                    set_email(event.target.value);
                  }}
                />
              </Box>

              <Box style={{ marginTop: 15, marginBottom: 15 }}>
                <InputLabel required>{tr("PASSWORD")}</InputLabel>
                <Input
                  error={isValidInput}
                  required
                  fullWidth
                  name="password"
                  type={showPassword ? "text" : "password"}
                  id="password"
                  autoComplete="current-password"
                  onChange={(event) => {
                    set_password(event.target.value);
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </Box>

              <Box style={{ marginTop: 15, marginBottom: 15 }}>
                <InputLabel required>Re-Enter Password</InputLabel>
                <Input
                  error={isPasswordMatch}
                  required
                  fullWidth
                  name="re-enter-password"
                  type={showPasswordReEnter ? "text" : "password"}
                  id="re-enter-password"
                  autoComplete="re-enter-current-password"
                  onChange={(event) => {
                    set_re_enter_password(event.target.value);
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPasswordReEnter}
                      >
                        {showPasswordReEnter ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </Box>

              <Button
                variant="contained"
                fullWidth
                color={"primary"}
                onClick={onSignUp}
              >
                {tr("SIGN_UP")}
              </Button>

              <Grid container>
                <Grid item xs>
                  <Link href="/reset_password" variant="body2">
                    {tr("FORGOT_PASSWORD")}
                  </Link>
                </Grid>
                <Grid item />
              </Grid>
            </Box>
          </Container>
          <LanguageMenu />
        </Grid>
      </div>
    </ThemeProvider>
  );
}
