import Lang from "../types/lang";

const Languages: Lang[] = [
  { key: "en", value: "English", type: "ltr", primary: false },
  { key: "ar", value: "عربي", type: "rtl", primary: false },
  { key: "es", value: "Española", type: "ltr", primary: false },
  { key: "de", value: "Deutsche", type: "ltr", primary: false },
  { key: "fr", value: "French", type: "ltr", primary: false },
  { key: "tl", value: "Tagalog", type: "ltr", primary: false },
  { key: "id", value: "Indonesia", type: "ltr", primary: false },
  { key: "ur", value: "اردو", type: "rtl", primary: false },
  { key: "tr", value: "Türk", type: "ltr", primary: false },
  { key: "ru", value: "русский", type: "ltr", primary: false },
  { key: "hi", value: "हिन्दी", type: "ltr", primary: false },
  { key: "it", value: "Italiano", type: "ltr", primary: false },
  { key: "ne", value: "नेपाली", type: "ltr", primary: false },
  { key: "pa", value: "ਪੰਜਾਬੀ", type: "ltr", primary: false },
  { key: "nl", value: "Dutch", type: "ltr", primary: false },
  { key: "pt", value: "Português", type: "ltr", primary: false },
  { key: "pl", value: "Polskie", type: "ltr", primary: false },
  { key: "ja", value: "日本語", type: "ltr", primary: false },
  { key: "zh-CN", value: "简体中文", type: "ltr", primary: false },
  { key: "zh-TW", value: "中國傳統的", type: "ltr", primary: false },
  { key: "ko", value: "한국어", type: "ltr", primary: false },
];

export default Languages;
