import _ from "lodash";
import dictionary from "../constants/dictionary.json";
import { store } from "../redux/store";

export default function tr(key) {
  //got language
  const lang = store.getState().language;
  const Language = lang.key;

  if (dictionary) {
    if (_.isEmpty(_.get(dictionary, key))) {
      return key.toString();
    }

    if (Language === "en" || Language === "ar") {
      return dictionary[key][Language];
    } else {
      return dictionary[key]["translated"][Language];
    }
  }
  return key;
}
