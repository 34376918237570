import {
  Box,
  Button,
  Container,
  Grid,
  Input,
  InputLabel,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import LanguageMenu from "../components/LanguagesMenu";
import { restPassword } from "../firebase";
import { theme } from "../theme";
import Lang from "../types/lang";
import tr from "../utils/translation";

export default function ResetPasswordScreen() {
  const lang = useSelector((state: any) => state.language as Lang);

  const [email, set_email] = useState<string>("");
  const [isClick, set_isClick] = useState<boolean>(false);

  const reset = useCallback(async () => {
    await restPassword(email);
    set_isClick(true);
  }, [email]);

  return (
    <ThemeProvider theme={theme}>
      <div dir={lang.type}>
        <Grid
          container
          direction={"column"}
          alignItems={"center"}
          justifyContent={"center"}
          style={{ minHeight: "87vh" }}
        >
          <Container maxWidth={"xs"}>
            <Typography component="h1" variant="h5">
              Reset Password
            </Typography>
            <Box component="form">
              <Box style={{ marginTop: 15, marginBottom: 15 }}>
                <InputLabel required>{tr("EMAIL")}</InputLabel>
                <Input
                  required
                  fullWidth
                  id="email"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  disabled={isClick && email ? true : false}
                  onChange={(event) => {
                    set_email(event.target.value);
                  }}
                />
              </Box>

              <Button
                variant="contained"
                fullWidth
                disabled={isClick && email ? true : false}
                color={"primary"}
                onClick={reset}
              >
                {tr("RESET")}
              </Button>
            </Box>
          </Container>
          <LanguageMenu />
        </Grid>
      </div>
    </ThemeProvider>
  );
}
