import { Grid, Stack, Typography } from "@mui/material";
import { width } from "@mui/system";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useLocation } from "react-router-dom";

const columns: GridColDef[] = [
  {
    field: "client_id",
    headerName: "Client ID",
    flex: 1,
    editable: false,
  },
  {
    field: "branch_id",
    headerName: "Branch ID",
    flex: 1,
    editable: false,
  },
];

export default function ClientsScreen() {
  const location = useLocation() as any;
  const clients = location.state.data.clients;
  const project_name = location.state.data.project_name;

  return (
    <Grid container flexGrow={1} p={3}>
      <Grid item xs={12} />
      <Grid item xs={12} style={{ width: "100%", height: 750 }}>
        {!clients ? (
          <>
            <Typography variant="h5" mb={2}>
              {project_name}
            </Typography>
            <Stack
              width={"100%"}
              height={"100%"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Typography variant="h5" mb={2} color={"GrayText"}>
                No Clients Found
              </Typography>
            </Stack>
          </>
        ) : (
          <>
            <Typography variant="h5" mb={2}>
              {project_name}
            </Typography>
            <DataGrid
              rows={clients}
              columns={columns}
              getRowId={(row) => row.branch_id}
              pageSize={8}
              disableSelectionOnClick
            />
          </>
        )}
      </Grid>
    </Grid>
  );
}
