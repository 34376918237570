import { createSlice } from "@reduxjs/toolkit";

export const manageAuth = createSlice({
  name: "login",
  initialState: {
    is_login: false,
    user: null,
    developer: null,
    project_ids: [],
  },
  reducers: {
    set_is_login: (state, action) => {
      if (action.payload === false) state.user = null;
      state.is_login = action.payload;
    },
    set_user_logged_in: (state, action) => {
      state.user = action.payload;
    },
    set_developer: (state, action) => {
      state.developer = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { set_is_login, set_user_logged_in, set_developer } =
  manageAuth.actions;

export default manageAuth.reducer;
