import CloseIcon from "@mui/icons-material/Close";
import { Stack } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import * as React from "react";
import ReactJson from "react-json-view";
import copyTextToClipboard from "../../utils/copyTextToClipboard";
import { RenderMethod, RenderStatus } from "../LogsTable";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export default function RequestDialog({ data }: any) {
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  const copyToClipboard = (text: string) => {
    copyTextToClipboard(text);
  };

  return (
    <div style={{ width: "100%" }}>
      <Button color="info" size="small" onClick={handleClick}>
        More Info
      </Button>
      <BootstrapDialog
        fullWidth
        maxWidth={"lg"}
        onClose={handleClick}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClick}
        >
          <Stack direction={"row"} alignItems={"center"} spacing={2}>
            <RenderStatus response={data.response} />
            <RenderMethod method={data.method} />
            <Typography fontWeight={"bold"} fontSize={16}>
              {data.route}
            </Typography>
          </Stack>
          <Typography fontSize={11} mt={1}>
            {data.request_time}
          </Typography>
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Typography fontWeight={"bold"} fontSize={16} m={1}>
            Headers:
          </Typography>
          <ReactJson
            src={data.headers}
            collapsed={true}
            enableClipboard={false}
            displayDataTypes={false}
          />
          <Stack flexDirection={"row"} justifyContent={"space-between"}>
            <Typography fontWeight={"bold"} fontSize={16} m={1}>
              Query:
            </Typography>
            <Button onClick={() => copyToClipboard(JSON.stringify(data.query))}>
              Copy
            </Button>
          </Stack>
          <ReactJson
            src={data.query}
            enableClipboard={false}
            displayDataTypes={false}
          />
          <Stack flexDirection={"row"} justifyContent={"space-between"}>
            <Typography fontWeight={"bold"} fontSize={16} m={1}>
              Body:
            </Typography>
            <Button onClick={() => copyToClipboard(JSON.stringify(data.body))}>
              Copy
            </Button>
          </Stack>

          <ReactJson
            src={data.body}
            theme={"monokai"}
            enableClipboard={false}
            displayDataTypes={false}
          />
          <Stack direction={"row"} justifyContent={"space-between"}>
            <Stack direction={"row"} alignItems={"center"}>
              <Typography fontWeight={"bold"} fontSize={16} m={1}>
                Response:
              </Typography>{" "}
              <RenderStatus response={data.response} />
            </Stack>
            <Button
              onClick={() => copyToClipboard(JSON.stringify(data.response))}
            >
              Copy
            </Button>
          </Stack>

          <ReactJson
            src={data.response.data || data.response}
            theme={"monokai"}
            style={{ width: "100%" }}
            enableClipboard={false}
            displayDataTypes={false}
          />
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}
