import { Cancel, CheckCircle, MailRounded } from "@mui/icons-material";
import {
  AppBar,
  Avatar,
  Badge,
  Box,
  Button,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import { doc, onSnapshot } from "firebase/firestore";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { db, logout, manageInvite } from "../../firebase";
import { set_is_login } from "../../redux/reducers/manageAuth";
import Invite from "../../types/Invite";
import Lang from "../../types/lang";

const pages = [
  { title: "API Docs", href: "https://api.lazywait.com/" },
  { title: "Contact", href: "https://lazywait.com/en/contact-us/" },
  { title: "About Us", href: "https://lazywait.com/en/our-story/" },
];
const settings = ["Logout"]; //["Profile", "Account", "Dashboard", "Logout"];

export default function MenuAppBar() {
  const [invitations, setInvitations] = useState<Invite[]>([]);

  const lang = useSelector((state: any) => state.language as Lang);
  const user = useSelector((state: any) => state.auth.user);
  const is_login = useSelector((state: any) => state.auth.is_login);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSignOut = async () => {
    // logout action
    if (await logout()) {
      dispatch(set_is_login(false)); // set the login to false
      handleCloseUserMenu(); // close the menu after click on the menu
      navigate("/"); // go to the login screen
    }
  };

  const handleAcceptInvite = useCallback(
    async (userEmail: string, developer_id: string) => {
      const updated_invitation = await manageInvite(
        "ACCEPT",
        userEmail,
        developer_id
      );
      setInvitations(updated_invitation as any);
      window.location.reload();
    },
    []
  );

  const handleRejectInvite = useCallback(
    async (userEmail: string, developer_id: string) => {
      const updated_invitation = await manageInvite(
        "REJECT",
        userEmail,
        developer_id
      );
      setInvitations(updated_invitation as any);
      window.location.reload();
    },
    []
  );

  useEffect(() => {
    const unsub = onSnapshot(doc(db, `users/${user?.id}`), (doc) => {
      const invites = doc.data()?.invitations;
      setInvitations(invites || []);
    });

    return () => {
      unsub();
    };
  }, [user]);

  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );
  const [anchorElInvitation, setAnchorElInvitation] =
    React.useState<null | HTMLElement>(null);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleOpenInvitationMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElInvitation(event.currentTarget);
  };

  const handleCloseInvitationMenu = () => {
    setAnchorElInvitation(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleAction = (setting: string) => {
    switch (setting) {
      case "Logout":
        handleSignOut();
        break;

      default:
        break;
    }
  };

  return (
    <AppBar position="static">
      <Toolbar sx={{ height: 48, justifyContent: "space-between" }}>
        <img
          src="./lazywait_dark.png"
          width={117}
          style={{ cursor: "pointer" }}
          alt="Home"
          onClick={() => {
            navigate("/");
          }}
        />
        <Typography
          color={"gray"}
          sx={{
            ml: 2,
            display: { xs: "none", md: "flex" },
            fontWeight: 700,
            letterSpacing: ".1rem",
            color: "#e0e0e0",
            textDecoration: "none",
          }}
        >
          Developer
        </Typography>

        <Box sx={{ mx: 10, flexGrow: 1, display: { xs: "none", md: "flex" } }}>
          {pages.map((page) => (
            <Button
              key={page.title}
              href={page.href}
              target="_blank"
              // onClick={handleCloseNavMenu}
              sx={{ my: 1, color: "white", display: "block" }}
            >
              {page.title}
            </Button>
          ))}
        </Box>

        {!is_login ? null : (
          <Box sx={{ flexGrow: 0 }}>
            <Stack direction={"row"} alignItems={"center"} spacing={1}>
              {!invitations.length ? null : (
                <Tooltip title="Received Invitations">
                  <IconButton
                    color="inherit"
                    onClick={handleOpenInvitationMenu}
                  >
                    <Badge badgeContent={invitations.length} color="error">
                      <MailRounded />
                    </Badge>
                  </IconButton>
                </Tooltip>
              )}

              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ pl: 2 }}>
                  <Avatar
                    alt={user.displayName}
                    // sx={{ width: 24, height: 24 }}
                    src={user.photoURL}
                  />
                </IconButton>
              </Tooltip>
            </Stack>

            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElInvitation}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElInvitation)}
              onClose={handleCloseInvitationMenu}
            >
              {invitations?.map((invite: any, index: any) => (
                <MenuItem key={`${index}-${invite.developer?.id}`}>
                  <Stack flexDirection={"row"} alignItems={"center"}>
                    <Stack mr={2}>
                      <Typography
                        variant="body2"
                        textAlign="left"
                        fontWeight={"bold"}
                      >
                        {invite.developer?.project_name}
                      </Typography>
                      <Typography
                        variant="body2"
                        textAlign="left"
                        color={"GrayText"}
                      >
                        {invite.from?.name}
                      </Typography>
                    </Stack>

                    <Stack flexDirection={"row"} justifyContent={"right"}>
                      <IconButton
                        color={"error"}
                        onClick={() =>
                          handleRejectInvite(user?.email, invite.developer?.id)
                        }
                      >
                        <Cancel />
                      </IconButton>
                      <IconButton
                        color={"success"}
                        onClick={() =>
                          handleAcceptInvite(user?.email, invite.developer?.id)
                        }
                      >
                        <CheckCircle />
                      </IconButton>
                    </Stack>
                  </Stack>
                </MenuItem>
              ))}
            </Menu>

            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem>
                <Typography textAlign="center" fontWeight={"bold"}>
                  {user.displayName}
                </Typography>
              </MenuItem>
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={() => handleAction(setting)}>
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
}
