import React from "react";

import SettingProjectDialog from "./SettingProjectDialog";
import { useSelector } from "react-redux";
import {
  Card,
  CardActions,
  CardContent,
  Button,
  Typography,
  Chip,
  Stack,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Group, Settings, Storage, Store } from "@mui/icons-material";
import TeamProjectDialog from "./TeamProjectDialog";

export default function ProjectCard({ data }: any) {
  const navigate = useNavigate();

  const [openSetting, setOpenSetting] = React.useState(false);
  const [openTeam, setOpenTeam] = React.useState(false);

  const { user } = useSelector((state: any) => state.auth);
  const isProjectOwner = data?.email === user?.email ? true : false;

  const handleClickSetting = () => {
    setOpenSetting(!openSetting);
  };

  const handleClickTeam = () => {
    setOpenTeam(!openTeam);
  };

  return (
    <div>
      <Card
        style={{
          minWidth: 275,
          margin: 25,
        }}
      >
        <CardContent>
          <Typography variant="h5" component="h2">
            {data.project_name}
          </Typography>
          <Stack direction={"row"} spacing={1}>
            <Chip
              size="small"
              variant="outlined"
              color={data.revoked ? "error" : "success"}
              label={data.revoked ? "Deactivate" : "Active"}
            />
            {!isProjectOwner ? null : (
              <Chip label="Owner" size="small" color="info" />
            )}
          </Stack>

          <Typography variant="body2" component="p" mt={1}>
            Owner: {data.email}
          </Typography>
        </CardContent>
        <CardActions>
          {!isProjectOwner ? null : (
            <>
              <Button
                size="small"
                onClick={() =>
                  navigate("/clients", {
                    state: {
                      data: data,
                    },
                  })
                }
                startIcon={<Store />}
              >
                Clients
              </Button>
              <Button
                size="small"
                onClick={handleClickTeam}
                startIcon={<Group />}
              >
                Manage Team
              </Button>
            </>
          )}
          <Button
            size="small"
            startIcon={<Storage />}
            onClick={() =>
              navigate("/projectLogs", {
                state: {
                  id: data.id,
                  name: data.project_name,
                  log_operations: data.log_operations,
                },
              })
            }
          >
            Logs
          </Button>
          <Button
            size="small"
            onClick={handleClickSetting}
            startIcon={<Settings />}
          >
            Settings
          </Button>
        </CardActions>
      </Card>
      <SettingProjectDialog
        data={data}
        isProjectOwner={isProjectOwner}
        open={openSetting}
        handleClick={handleClickSetting}
      />
      <TeamProjectDialog
        open={openTeam}
        data={data}
        handleClick={handleClickTeam}
      />
    </div>
  );
}
