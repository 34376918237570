import React from "react";
import { FormHelperText, Grid, Stack, Switch, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import LogsTable from "../components/LogsTable";
import { updateLogOperation } from "../firebase";

export default function ProjectLogsScreen() {
  const location = useLocation() as any;

  const [isLogEnabled, setIsLogEnabled] = React.useState(
    location.state.log_operations
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsLogEnabled(event.target.checked);
    // update log operations in firebase
    updateLogOperation(location.state.id, event.target.checked);
  };

  return (
    <Grid container flexGrow={1} p={3}>
      <Grid item xs={12} />
      <Grid item xs={12}>
        <Stack
          flexDirection={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          mb={2}
        >
          <Typography variant="h5">{location.state.name}</Typography>
          <Stack flexDirection={"row"} alignItems={"center"}>
            <FormHelperText>Enable Recording Logs</FormHelperText>
            <Switch
              color="secondary"
              checked={isLogEnabled}
              onChange={handleChange}
              name="enableLogs"
            />
          </Stack>
        </Stack>

        <LogsTable project_id={location.state.id} />
      </Grid>
    </Grid>
  );
}
