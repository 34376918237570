import { Visibility, VisibilityOff, ContentCopy } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
} from "@mui/material";

import * as React from "react";
import { updateWebhook } from "../../firebase";
import copyTextToClipboard from "../../utils/copyTextToClipboard";

export default function SettingProjectDialog({
  data,
  isProjectOwner,
  open,
  handleClick,
}: any) {
  // check if the the logged-in is the owner of the project
  const [showToken, setShowToken] = React.useState(false);
  const [webhook_url, set_webhook_url] = React.useState(data.webhook_url);

  const handleClickShowToken = () => {
    setShowToken(!showToken);
  };

  const handleUpdate = async () => {
    await updateWebhook(data.id, webhook_url);
    handleClick();
  };

  const copyToClipboard = (text: string) => {
    copyTextToClipboard(text);
  };

  return (
    <Dialog open={open} onClose={handleClick} fullWidth>
      <DialogTitle>{data.project_name}</DialogTitle>
      <DialogContent>
        <FormControl margin="normal" fullWidth>
          <InputLabel htmlFor="input-with-icon-adornment">Webhook</InputLabel>
          <Input
            id="input-with-icon-adornment"
            value={webhook_url}
            onChange={(event) => set_webhook_url(event.currentTarget.value)}
            disabled={!isProjectOwner}
          />
        </FormControl>

        <FormControl margin="normal" fullWidth>
          <InputLabel htmlFor="standard-adornment-password">Token</InputLabel>
          <Input
            id="standard-adornment-password"
            type={showToken ? "text" : "password"}
            value={data.token}
            disabled
            autoComplete={"off"}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowToken}
                >
                  {showToken ? <Visibility /> : <VisibilityOff />}
                </IconButton>
                <IconButton
                  aria-label="toggle copy token"
                  onClick={() => copyToClipboard(data.token)}
                >
                  <ContentCopy />
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        {!isProjectOwner ? null : (
          <Button onClick={handleUpdate} color="primary">
            Update
          </Button>
        )}
        <Button onClick={handleClick} color={"info"}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
